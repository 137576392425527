import { StaticImageData } from "next/image";
import { Role } from "@/ts/business/role/Role";
import standardIcon from "@/res/roles/standard.png";
import memburIcon from "@/res/roles/membur.png";
import memburGreenIcon from "@/res/roles/membur_green.png";
import teamIcon from "@/res/roles/team.png";
import adminIcon from "@/res/roles/admin.png";


export function getRoleIcon(role: Role): StaticImageData {
    if (role === Role.STANDARD)
        return standardIcon;
    if (role === Role.MEMBUR)
        return memburIcon;
    if (role === Role.TEAM)
        return teamIcon;
    if (role === Role.ADMIN)
        return adminIcon;

    throw new Error("Unknown role type, " + role.getID());
}


export function getGreenMemburIcon(): StaticImageData {
    return memburGreenIcon;
}


export function getRoleColours(role: Role): [string, string] {
    if (role === Role.STANDARD)
        return ["#000000", "#81848b"];
    if (role === Role.MEMBUR)
        return ["#ffffff", "#ebb402"];
    if (role === Role.TEAM)
        return ["#ffffff", "#599ccb"];
    if (role === Role.ADMIN)
        return ["#ffffff", "#e81e51"];

    throw new Error("Unknown role type, " + role.getID());
}


export function getRoleColour(role: Role): string {
    return getRoleColours(role)[1];
}


export function getGreenMemburColour(): string {
    return "#77c515";
}
