import { APIUser } from "@/ts/business/api/api_schema";

/**
 * The modes guide how games are presented and interacted with.
 */
export class Role {
    public static readonly STANDARD = new Role(
        "standard", "Standard",
        "Standard Playur", 0,
    );

    public static readonly MEMBUR = new Role(
        "membur", "Membur",
        "Valued Membur", 1,
    );

    public static readonly TEAM = new Role(
        "team", "Team",
        "Part of the RoyalUr.net team", 3,
    );

    public static readonly ADMIN = new Role(
        "admin", "Admin",
        "Administrator of RoyalUr.net", 4,
    );

    static values(): Role[] {
        return [
            Role.STANDARD,
            Role.MEMBUR,
            Role.TEAM,
            Role.ADMIN,
        ];
    }

    private readonly id: string;
    private readonly name: string;
    private readonly desc: string;
    private readonly priority: number;

    constructor(
        id: string,
        name: string,
        desc: string,
        priority: number,
    ) {
        this.id = id;
        this.name = name;
        this.desc = desc;
        this.priority = priority;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getDesc(): string {
        return this.desc;
    }

    static tryGetByID(id: string): Role | null {
        for (const role of Role.values()) {
            if (role.getID() === id)
                return role;
        }
        return null;
    }

    static getByID(id: string): Role {
        const role = Role.tryGetByID(id);
        if (role === null)
            throw new Error(`Unknown role ID: ${id}`);

        return role;
    }

    /**
     * Orders roles in ascending order of priority.
     */
    static sortRoles(roles: Role[]) {
        roles.sort(role => role.priority);
    }

    /**
     * Lists all the roles that apply to the given user.
     */
    static list(user: APIUser | null): Role[] {
        const roles: Role[] = [Role.STANDARD];
        if (!user)
            return roles;

        if (user.hasRoleMembur) {
            roles.push(Role.MEMBUR);
        }
        if (user.hasRoleTeam) {
            roles.push(Role.TEAM);
        }
        if (user.hasRoleAdmin) {
            roles.push(Role.ADMIN);
        }
        Role.sortRoles(roles);
        return roles;
    }

    static get(user: APIUser | null): Role {
        const roles = Role.list(user);
        return roles[roles.length - 1];
    }
}

